import axios from '@/plugins/axios'
import { cloneDeep } from 'lodash-es'

export const namespaced = true;

export const state = () => ({
    entities: [],
    settings: {},
    entityTypes: [],
    entitiesCount: 0,
    entity: {},
    categories: [],
    products: [],
    productsCount: 0,
    popular_list: [],
    recomendations: [],
    product: {},
    waitingPrice: {},
    additionalDescriptionEntity: {},
    additionalDescriptionCategory: {},
});

export const mutations = {
    CLEAR_ENTITIES(state) {
        state.entities = [];
        state.settings = {};
        state.entityTypes = [];
        state.entitiesCount = 0;
    },
    CLEAR_ENTITY(state) {
        state.entity = {};
        state.categories = [];
    },
    CLEAR_CATEGORIES(state) {
        state.categories = [];
    },
    CLEAR_PRODUCTS(state) {
        state.products = [];
        state.productsCount = 0;
    },
    CLEAR_PRODUCT(state) {
        state.product = {};
    },
    CLEAR_ENTITY_SETTINGS(state) {
        state.settings = {};
    },
    SET_ENTITIES(state, payload) {
        state.entities = payload;
    },
    SET_ENTITY_TYPES(state, payload) {
        state.entityTypes = payload;
    },
    SET_ENTITY_SETTINGS(state, payload) {
        state.settings = payload;
    },
    SET_ENTITY(state, payload) {
        state.entity = payload;
    },
    SET_CATEGORIES(state, payload) {
        state.categories = payload;
    },
    SET_PRODUCTS(state, payload) {
        if(payload.page === 1) {
            state.products = [];
        }
        state.products = state.products.concat(payload.products);
        state.productsCount = payload.productsCount;
    },
    SET_POPULAR(state, payload) {
        state.popular_list = payload;
    },
    SET_RECOMENDATIONS(state, payload) {
        state.recomendations = payload;
    },
    SET_PRODUCT(state, payload) {
        state.product = payload;
    },
    SET_WAITING_PRICE(state, payload) {
        state.waitingPrice = payload;
    },
    SET_ADDITIONAL_DESCRIPTION_ENTITY(state, payload) {
        state.additionalDescriptionEntity = payload;
    },
    SET_ADDITIONAL_DESCRIPTION_CATEGORY(state, payload) {
        state.additionalDescriptionCategory = payload;
    },
};

export const actions = {
    async fetchTypes({ commit }) {
        const { data } = await axios.get('/front/entities-types');
        commit('SET_ENTITY_TYPES', data);
    },
    async fetchEntitySettings({ commit }, { entityId }) {
        const { data } = await axios.get(`/front/entities/settings/${entityId}`);
        commit('SET_ENTITY_SETTINGS', data);
    },
    async fetch({ commit }, { entityTypeCode }) {
        commit('SET_ENTITIES', []);
        const { data } = await axios.get(`/front/entities/get-by-code/${entityTypeCode}`);
        commit('SET_ENTITIES', data);
        return data;
    },
    async fetchByTower({ commit, state }, { entityTypeCode, tower_id }) {
        commit('SET_ENTITIES', []);
        const { data } = await axios.get(`/front/entities/sort-for-tower/${entityTypeCode}/${tower_id}`);
        commit('SET_ENTITIES', data);
        return data;
    },
    async get({ commit }, { entityId }) {
        const { data } = await axios.get(`/front/entities/get/${ entityId }`);
        commit('SET_CATEGORIES', data.categories);
        delete data.categories;
        commit('SET_ENTITY', data);
    },
    async getByCode({ commit }, { entityCode }) {
        const { data } = await axios.get(`/front/entities/code/${ entityCode }`);
        commit('SET_CATEGORIES', data.categories);
        delete data.categories;
        commit('SET_ENTITY', data);
        return data;
    },
    async getProducts({ commit }, { entityId, categoryId, page }) {
        if(page === 1) {
            commit('SET_PRODUCTS', { products: [], productsCount: 0, page: 1 });
        }
        const { data } = await axios.get(`/front/entities/${ entityId }/category/${ categoryId }/entity_products?page=${ page }`);
        commit('SET_PRODUCTS', { products: data.results, productsCount: data.total, page });
    },
    async getPopular({ commit }, { entityId }) {
        const { data } = await axios.get(`/front/entities/${ entityId }/popular`);
        commit('SET_POPULAR', data);
    },
    async getRecomendations({ commit }, { entityId }) {
        const { data } = await axios.get(`/front/entities/${ entityId }/recomendations`);
        commit('SET_RECOMENDATIONS', data);
    },
    async getProduct({ commit }, { entityId, productId }) {
        commit('SET_PRODUCT', {});
        const { data } = await axios.get(`/front/entities/${ entityId }/entity_products/${ productId }`);
        commit('SET_PRODUCT', data);
    },
    async getWaitingPrice({ commit }, { type, id }) {
        const { data } = await axios.get(`/front/entities/waiting-price/${ type }/${ id }`);
        commit('SET_WAITING_PRICE', data);
    },
    async getAdditionalDescriptionEntity({ commit }, { entityType, entityId }) {
        const { data } = await axios.get(`/front/entities/additional-description/${ entityType }/${ entityId }/null`);
        commit('SET_ADDITIONAL_DESCRIPTION_ENTITY', data);
    },
    async getAdditionalDescriptionCategory({ commit }, { entityType, entityId, categoryId }) {
        const { data } = await axios.get(`/front/entities/additional-description/${ entityType }/${ entityId }/${ categoryId }`);
        commit('SET_ADDITIONAL_DESCRIPTION_CATEGORY', data);
    },
};

export const getters = {
    categoriesFirstLevel: state => {
        return state.categories.filter(c => !c.parent_id);
    },
    showCutlery: state => !!state.settings.show_cutlery,
    entityTypes: ({ entityTypes }) => entityTypes,
    
    categoriesChildrenMap: ({ categories }) => {
        const res = { 0: [] };

        categories.forEach(category => {
            if (!category.parent_id) {
                res[0].push(category);
                return;
            }

            if (res[category.parent_id]) res[category.parent_id].push(category);
            else res[category.parent_id] = [category];
        });

        return res;
    },
    
    categoriesByIdMap: ({ categories }) => {
        const res = {};

        categories.forEach(category => {

            res[category.id ?? 0] = category;

            return res;
        });

        return res;
    },

    addedSubcategoriesCategoriesByIdMap: (state, { categoriesByIdMap, categoriesChildrenMap }) => {
        const res = {
            0: { id: 'root', subcategories: categoriesChildrenMap[0] }
        };

        Object.keys(categoriesByIdMap).forEach(id => {
            const newCategory = { ...categoriesByIdMap[id], subcategories: categoriesChildrenMap[id] ?? [] };

            if (!res[id]) res[id] = newCategory;
        });

        return res;
    },

    entitiesByCodeMap(state) {
        const res = {};

        state.entityTypes.forEach(entityType => {
            res[entityType.code] = cloneDeep(entityType);
        });

        return res;
    }
};
