<template>
    <div class="overlay">
        <div class="stories__wrapper">
            <!-- Области для кликов влево и вправо -->
            <div class="stories__click-area stories__click-area__prev" @click="prev"></div>
            <div class="stories__click-area stories__click-area__next" @click="next"></div>

            <div class="stories">
                <div
                    v-for="(story, index) in onboarding.stories"
                    :key="index"
                    class="story"
                    :style="getStoryStyle(index)"
                >
                    <div class="story__source">
                        <img :src="imageSrc(story.image)" alt="" />

                        <!-- Кнопка на сторисе -->
                        <button
                            v-if="story.enabled_button"
                            class="story__button"
                            :style="{ backgroundColor: story.color_button || '#FD7D89' }"
                            @click.stop="onButtonClick(story)"
                        >
                            {{ story.title_button || 'Перейти' }}
                        </button>
                    </div>
                </div>

                <div class="stories__progress" v-show="onboarding.pagination === 1">
                    <div
                        v-for="(elm, idx) in onboarding.stories.length"
                        :key="idx"
                        class="stories__progress__item"
                    >
                        <div
                            class="stories__progress__item__fill"
                            :style="getTimeFillStyle(idx)"
                        ></div>
                    </div>
                </div>
                <div class="stories__close" @click="closeStories"></div>
            </div>
        </div>

        <div class="stories__pagination" v-show="onboarding.pagination === 2">
            <div class="stories__pagination__prev" @click="prev"></div>
            <div class="stories__pagination__items">
                <div
                    v-for="(elm, idx) in onboarding.stories.length"
                    :key="idx"
                    class="stories__pagination__item"
                >
                    <div
                        v-show="indexSelected === idx"
                        class="stories__pagination__item__fill"
                    ></div>
                </div>
            </div>
            <div class="stories__pagination__next" @click="next"></div>
        </div>
    </div>
</template>

<script>
    import { f7 } from 'framework7-vue';
    import { goToExternalLink, sendAppMetricaEvent } from '@/js/utils/service-helpers';

    export default {
        name: 'OnboardingStories',
        props: {
            onboarding: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                indexSelected: 0,
            };
        },
        computed: {
            isLastStory() {
                return this.indexSelected >= this.onboarding.stories.length - 1;
            },
        },
        mounted() {
            // Убираем вызов play(), так как автоматического переключения нет
        },
        methods: {
            imageSrc(picture) {
                return picture ? `${process.env.VUE_APP_IMAGES_URL}/${picture}` : '';
            },
            getStoryStyle(index) {
                return index === this.indexSelected
                    ? 'opacity: 1;'
                    : 'opacity: 0; pointer-events: none;';
            },
            getTimeFillStyle(idx) {
                return idx <= this.indexSelected ? 'width: 100%' : 'width: 0%';
            },
            // Убираем методы play, pauseStory, playStory
            next() {
                if (this.isLastStory) {
                    this.closeStories();
                } else {
                    this.indexSelected += 1;
                }
            },
            prev() {
                if (this.indexSelected > 0) {
                    this.indexSelected -= 1;
                }
            },
            async onButtonClick(story) {
                if (!story?.link_button) {
                    return;
                }

                if (story.link_type && story.link_button) {
                    sendAppMetricaEvent('ONBOARDING_STORY_CLICK', {
                        Action: 'click',
                        Page: 'main',
                        StoryId: this.story?.id
                    });
                }

                switch(story.link_type) {
                case 'link':
                    await f7.views[0].router.navigate(story.link_button);
                    this.$f7.sheet.close();
                    this.$root.$emit('onboardingClose');
                    this.shown = false;
                    break
                case 'external_link':
                    goToExternalLink(story.link_button);
                    this.$f7.sheet.close();
                    this.$root.$emit('onboardingClose');
                    this.shown = false;
                    break;
                default:
                    this.$f7.sheet.close();
                    this.$root.$emit('onboardingClose');
                    this.shown = false;
                    return false;
                }
            },
            closeStories() {
                this.$emit('stories-end');
            },
        },
    };
</script>

<style lang="scss" scoped>
.overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    // height: calc(100% + env(safe-area-inset-top));
    // height: calc(100% + constant(safe-area-inset-top));
    height: 100%;
    background-color: #fff; /* Белый фон за stories__wrapper */
    z-index: 99998; /* На слой ниже, чем stories__wrapper */
}


.stories {
    position: relative;
    width: 100%;
    height: 100%;
    
    &__wrapper {
        position: fixed;
        top: env(safe-area-inset-top);
        top: constant(safe-area-inset-top);
        left: 0;
        bottom: calc(112px + env(safe-area-inset-bottom));
        bottom: calc(112px + constant(safe-area-inset-bottom));
        right: 0;
        background: #fff; /* Изменено на белый фон */
        border-radius: 20px; /* Добавлено скругление углов */
        overflow: hidden; /* Чтобы содержимое не выходило за границы */
        z-index: 99999;
    }

    /* Области для кликов влево и вправо */
    &__click-area {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50%;
        z-index: 100000;
        background: rgba(0, 0, 0, 0); /* Прозрачный фон */

        &__prev {
            left: 0;
        }

        &__next {
            right: 0;
        }
    }

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 32px;
        height: 32px;
        background-color: rgba(255, 255, 255, 0.24);
        border-radius: 50%;
        cursor: pointer;
        background-image: url('../../static/close-onboarding.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        z-index: 100001;
    }

    &__progress {
        position: absolute;
        top: 16px;
        left: 16px;
        right: 60px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 100001;
        flex: 1;

        &__item {
            flex: 1;
            height: 4px;
            background: rgba(255, 255, 255, 0.5);
            margin-right: 4px;
            border-radius: 2px;
            position: relative;
        }

        &__item:last-child {
            margin-right: 0;
        }

        &__item__fill {
            position: absolute;
            top: 0;
            left: 0;
            height: 4px;
            background: #fff;
            border-radius: 2px;
            transition: none;
        }
    }

    &__pagination {
        position: fixed;
        left: 0;
        bottom: calc(50px + env(safe-area-inset-bottom));
        bottom: calc(50px + constant(safe-area-inset-bottom));
        width: 100%;
        height: 52px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__prev {
            width: 52px;
            height: 52px;
            cursor: pointer;
            background-image: url('../../static/stories-prev.svg');
            background-repeat: no-repeat;
            background-position: center;
        }
        &__next {
            width: 52px;
            height: 52px;
            cursor: pointer;
            background-image: url('../../static/stories-next.svg');
            background-repeat: no-repeat;
            background-position: center;
        }
        &__items {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__item {
            width: 6px;
            height: 6px;
            margin-right: 4px;
            border-radius: 30px;
            background: #9599A44D;
            overflow: hidden;

            &__fill {
                width: 6px;
                height: 6px;
                background: #FD7F85;
            }
        }
        &__item:last-child {
            margin-right: 0;
        }
    }
}

.story {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease;
    opacity: 0;

    &__source {
        width: 100%;
        height: 100%;
        position: relative;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__button {
        position: absolute;
        bottom: 23px;
        left: 50%;
        transform: translateX(-50%);
        padding: 14px 25px;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        background: #FD7D89;
        width: 91%;
        border-radius: 36px;
        z-index: 100001;
    }
}

</style>
