<template>
    <f7-app :params="f7params">
        <f7-view
            url="/"
            main
            :push-state="isPushState"
            class="safe-areas"
            :animate="false"
        />
        <Preloader />
        <MobileAppSheet />
        <TimeoutErrorSheet />
        <PushMessageSheet />
        <StatusObserver v-if="isMobile()" />
        <ForceUpdatePopup />
        <SurveyPreview />
        <Survey />
        <Stories v-if="isMobile()" />
        <AllStories v-if="isMobile()" />
        <!-- <AgreementVersionSheet v-if="isMobile()" /> -->
        <Onboarding
            v-if="isMobile()"
            @waiting="checkIfSheetOpen"
            @processing="false"
        />
        <template v-if="isMobile()">
            <div v-for="(img, idx) in allImg" :key="idx">
                <link
                    :key="idx"
                    rel="preload"
                    :href="imageSrc(img)"
                    as="image" />
            </div>
        </template>
    </f7-app>
</template>
<script>
    import { mapState } from 'vuex';
    import { Device } from 'framework7';
    import F7 from 'framework7';
    import store from '../store';
    import packageJson from '/package.json';
    import cordovaApp from '../js/cordova-app.js';
    import routes from '../js/routes.js';
    import indexedDB from '../plugins/indexedDB';
    import { CUSTOMER_LOG_TYPE } from '../js/utils/vars.js';
    import { updatePushToken, pushAction, sendCustomerLog, getDeviceId, checkActiveSurvey, sendAppMetricaEvent } from '../js/utils/service-helpers.js';

    import Preloader from '../components/common/Preloader';
    import MobileAppSheet from '../components/common/MobileAppSheet';
    import TimeoutErrorSheet from '../components/common/TimeoutErrorSheet';
    import PushMessageSheet from '../components/common/PushMessageSheet';
    import Onboarding from '../components/dialogs/Onboarding';
    import ForceUpdatePopup from '../components/common/ForceUpdatePopup';
    import SurveyPreview from '../components/common/SurveyPreview';
    import Survey from '../components/common/Survey';
    import Stories from '../components/dialogs/Stories';
    import AllStories from '../components/dialogs/AllStories';
    // import AgreementVersionSheet from '../components/dialogs/AgreementVersionSheet';
    import StatusObserver from '../components/dialogs/StatusObserver';
    import moment from '../plugins/moment';
    
    export default {
        components: {
            Preloader,
            MobileAppSheet,
            TimeoutErrorSheet,
            PushMessageSheet,
            StatusObserver,
            ForceUpdatePopup,
            SurveyPreview,
            Survey,
            Stories,
            AllStories,
            // AgreementVersionSheet,
            Onboarding
        },
        data() {
            return {
                canOpen: false,
                isPushState: !Device.cordova,
                // Framework7 Parameters
                f7params: {
                    id: 'ru.mircity24.mircity', // App bundle ID
                    name: 'mircity24-app', // App name
                    theme: 'ios', // Automatic theme detection
                    pushState: this.isPushState,
                    view: {
                        // reloadPages: true,
                        // preloadPreviousPage: false,
                        pushState: this.isPushState,
                        // pushStateSeparator: '',
                        // pushStateOnLoad: true
                    },
                    // Cache
                    cache: false,
                    cacheDuration: 0,
                    dialog: {
                        // set default title for all dialog shortcuts
                        title: ' ',
                        buttonOk: 'ок',
                        buttonCancel: 'Отмена'
                    },

                    // App routes
                    routes: routes,

                    // Register service worker
                    serviceWorker: Device.cordova
                        ? {}
                        : {
                            path: '/service-worker.js',
                        },
                    // Input settings
                    input: {
                        scrollIntoViewOnFocus: Device.cordova && !Device.electron,
                        scrollIntoViewCentered: Device.cordova && !Device.electron,
                    },
                    // Cordova Statusbar settings
                    statusbar: {
                        iosOverlaysWebView: true,
                        androidOverlaysWebView: false,
                    },
                },
                // Login screen data
                username: '',
                password: '',
                storiesOpen: '',
                allImg: [],
            };
        },
        computed: {
            ...mapState('settings', {
                settings: state => state.settings.stories
            }),
            ...mapState('orders', ['active']),
        },
        async mounted() {
            this.$f7ready(async f7 => {
                const this_ = this;
                
                // Init cordova APIs (see cordova-app.js)
                if (!(store.state.auth.user && store.state.auth.user.phone)) {
                    if (await getDeviceId()) {
                        await store.dispatch('auth/getProfile', { onlyCheck: true });
                    } else {
                        await store.commit('auth/SET_TOKEN', await indexedDB.get('mircity-token') || null);
                        await store.commit('auth/SET_USER', await indexedDB.get('mircity-user') || {});
                    }
                }
                await store.dispatch('settings/getStories');
                await store.dispatch('cart/getCacheData');
                
                if (store.state.auth.user && store.state.auth.user.phone) {
                    await store.dispatch('orders/fetch', { statuses: 'active', page: 1 });
                    await store.dispatch('auth/checkAgreementVersion');
                    console.log(store.state.auth.user, 'store.state.auth.user');
                    
                    if(store.state.auth.user.need_agree) {
                        this.$f7.views[0].router.navigate({ name: 'agreement' });
                        // this.$root.$emit('agreementVersionSheetShow');
                    }
                }
                
                checkActiveSurvey({ this_ });
                
                if (this.isMobile()) {
                    if (store.state.auth.user && store.state.auth.user.phone) {
                        await store.dispatch('orders/fetch', { statuses: 'active', page: 1 });
                    }
                    await store.dispatch('settings/getStories');
                    const images = [];
                    if (this_.settings && this_.settings[0] && this_.settings[0].pictures) {
                        for (const setting of this_.settings) {
                            if (setting) {
                                for (const picture of setting.pictures) {
                                    images.push(picture.picture);
                                }
                            }
                        }
                    }
                    this_.allImg = images;
                
                    store.dispatch('orders/updateActiveOrderSession');
                    // this_.$root.$emit('allStoriesStart', true);
                    this_.$root.$emit('popupBannerSheetShow');
                }

                if (Device.cordova) {
                    const customerLog = await indexedDB.get('mircity-customerLog') || {};
                    cordovaApp.init(f7);
                
                    try {
                        if(window.appMetrica && process.env.VUE_APP_YANDEX_APPMETRICA_API_KEY != '') {
                            await indexedDB.remove('mircity-appMetricaActivated');
                            const appMetricaParams = {
                                apiKey: process.env.VUE_APP_YANDEX_APPMETRICA_API_KEY,
                                handleFirstActivationAsUpdate: false,
                                sessionTimeout: 60,
                                appVersion: packageJson.version
                            };
                            if(customerLog[CUSTOMER_LOG_TYPE.FIRST_OPEN]) {
                                appMetricaParams.handleFirstActivationAsUpdate = true;
                            }
                            window.appMetrica.activate(appMetricaParams);
                            await new Promise(resolve => setTimeout(resolve, 500));
                            await indexedDB.set('mircity-appMetricaActivated', true);
                        }

                        sendAppMetricaEvent('APP_OPEN');
                    } catch(e) {}

                    sendCustomerLog(CUSTOMER_LOG_TYPE.FIRST_OPEN);
                    
                    try {
                        if(window.plugins && window.plugins.appsFlyer) {
                            window.plugins.appsFlyer.registerOnAppOpenAttribution(result => {
                                const deeplinkData = JSON.parse(result);  
                                // f7.dialog.create({
                                //     title: 'registerOnAppOpenAttribution',
                                //     text: JSON.stringify(deeplinkData, null, 2),
                                //     buttons: [
                                //         {
                                //             text: 'Закрыть'
                                //         }
                                //     ]
                                // }).open();
                                if(deeplinkData?.data?.deep_link_value){
                                    f7.views[0].router.navigate(deeplinkData.data.deep_link_value.replace(/_/g, '/'));
                                }
                            }, () => {});

                            window.plugins.appsFlyer.registerDeepLink(result => {
                                const deeplinkData = JSON.parse(result);  
                                // f7.dialog.create({
                                //     title: 'registerDeepLink',
                                //     text: JSON.stringify(deeplinkData, null, 2),
                                //     buttons: [
                                //         {
                                //             text: 'Закрыть'
                                //         }
                                //     ]
                                // }).open();
                                if(deeplinkData?.data?.deep_link_value){
                                    f7.views[0].router.navigate(deeplinkData.data.deep_link_value.replace(/_/g, '/'));
                                }
                            }, () => {});

                            window.plugins.appsFlyer.initSdk({  
                                devKey: 'ZNmAF8b5cDvjpPybD8uGGJ', // your AppsFlyer devKey  
                                isDebug: false,  
                                appId: '1534553688', // your ios appID  
                                waitForATTUserAuthorization: 10, //time for the sdk to wait before launch - IOS 14 ONLY!
                                onInstallConversionDataListener: true,
                                onDeepLinkListener: true
                            }, function(result) {
                                var conversionData = JSON.parse(result);
                                // f7.dialog.create({
                                //     title: 'initSdk',
                                //     text: JSON.stringify(conversionData, null, 2),
                                //     buttons: [
                                //         {
                                //             text: 'Закрыть'
                                //         }
                                //     ]
                                // }).open();
                                if(conversionData?.data?.deep_link_value){
                                    f7.views[0].router.navigate(conversionData.data.deep_link_value.replace(/_/g, '/'));
                                }
                            }, function() {});
                        }
                    } catch(e) {}

                    try {
                        if(window.universalLinks) {
                            window.universalLinks.subscribe('universalLinksEvent', eventData => {
                                if(eventData.hash && eventData.hash !== '') {
                                    f7.views[0].router.navigate(eventData.hash.substring(eventData.hash.indexOf('/')));
                                } else if(eventData.path && eventData.path !== '/') {
                                    f7.views[0].router.navigate(eventData.path);
                                }
                            });
                        }
                    } catch(e) {}

                    try {
                        if(window.FirebasePlugin) {
                            const FirebasePlugin = window.FirebasePlugin;

                            FirebasePlugin.onMessageReceived(function(message) {
                                if(message.messageType === 'notification') {
                                    if(message.tap) {
                                        pushAction({ 
                                            type: message.data_type, 
                                            data: { 
                                                link: message.data_link,
                                                order_id: message.data_order_id
                                            }
                                        });
                                    } else {
                                        this_.$root.$emit('pushMessageSheetShow', { 
                                            title: (message.aps && message.aps.alert && message.aps.alert.title) ? message.aps.alert.title : message.title, 
                                            text: (message.aps && message.aps.alert && message.aps.alert.body) ? message.aps.alert.body : message.body, 
                                            type: message.data_type, 
                                            data: { 
                                                link: message.data_link ? message.data_link : null,
                                                order_id: message.data_order_id ? message.data_order_id : null
                                            }
                                        });
                                    }
                                }
                            }, function(error) {});

                            FirebasePlugin.onTokenRefresh(function(token){
                                updatePushToken(true);
                            }, function(error) {});

                            const checkNotificationPermission = function(requested){
                                FirebasePlugin.hasPermission(function(hasPermission){
                                    if(hasPermission) {
                                        // Granted
                                        updatePushToken();
                                    } else if(!requested) {
                                        // Request permission
                                        FirebasePlugin.grantPermission(checkNotificationPermission.bind(this, true));
                                    } else {
                                        // Denied
                                    }
                                });
                            };

                            FirebasePlugin.setBadgeNumber(0);
                            checkNotificationPermission(false);

                            FirebasePlugin.fetchAndActivate(function(activated) {
                                FirebasePlugin.getAll(function(config) {
                                    store.commit('remoteConfig/SET_CONFIG', config);
                                }, function(error) {
                                    console.error(error);
                                });
                            }, function(error) {
                                console.error(error);
                            });
                        }
                    } catch(e) {}

                    const PUSH_DELAY = 15;
                    const deviceId = await getDeviceId();
                    document.addEventListener('pause', async () => {
                        try {
                            const cartItemsCount = store?.state?.cart?.items?.length || 0;
                            const activeOrdersCount = store?.state?.orders?.activeOrderSession || false;
                            const pushNotificationShow = await indexedDB.get('mircity-pushNotificationShow') || false;

                            if (cartItemsCount >= 2 && !activeOrdersCount && !pushNotificationShow) {
                                const timer = moment().add(PUSH_DELAY, 'minutes').format('YYYY-MM-DD HH:mm:ss');
                                await indexedDB.set('mircity-pushNotificationTimer', timer);
                                await store.dispatch('orders/createLocalPush', { push_data: timer, device_id: deviceId });
                            }
                        } catch (e) {
                            // f7.dialog.alert(`PAUSE ERR: ${e}`)
                        }
                    });

                    document.addEventListener('resume', async () => {
                        try {
                            sendAppMetricaEvent('APP_OPEN');

                            const timer = await indexedDB.get('mircity-pushNotificationTimer');
                            const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');

                            if (timer && moment(currentTime).isAfter(timer)) {
                                await indexedDB.set('mircity-pushNotificationShow', true);
                                await indexedDB.remove('mircity-pushNotificationTimer');
                                
                                const updatePushDB = await indexedDB.get('mircity-updatePushNotificationDB') || false;
                                if (!updatePushDB) {
                                    await store.dispatch('orders/sendLocalPush', { push_data: moment().format('YYYY-MM-DD HH:mm:ss'), device_id: deviceId });
                                    sendAppMetricaEvent('LOCAL_PUSH_NOTIFICATION', {
                                        'push_local_data': timer,
                                    });
                                } else {
                                    await store.dispatch('orders/deleteLocalPush', { push_data: moment().format('YYYY-MM-DD HH:mm:ss'), device_id: deviceId });
                                }
                                await indexedDB.set('mircity-updatePushNotificationDB', true);
                            } else {
                                await store.dispatch('orders/deleteLocalPush', { push_data: moment().format('YYYY-MM-DD HH:mm:ss'), device_id: deviceId });
                            }
                        } catch (e) {
                            // f7.dialog.alert(`RESUME ERR: ${e}`)
                        }
                    });

                    const deeplink = localStorage.getItem('mircity-deeplink');
                    if(deeplink && deeplink.substring(0, 9) === 'mircity24') {
                        localStorage.removeItem('mircity-deeplink');
                        f7.views[0].router.navigate(deeplink);
                    }
                    window.handleOpenURL = function(url) {
                        setTimeout(function() {
                            if(url.substring(0, 9) === 'mircity24') {
                                const deeplink = url.substring(url.indexOf('://') + 2);
                                f7.views[0].router.navigate(deeplink);
                            }
                        }, 0);
                    };
                } else {
                    this.isMobile() ? this_.$root.$emit('mobileAppSheetShow') : this_.$root.$emit('desktopSheetShow');
                }
            });
        },
        methods: {
            isMobile() {
                return screen.width <= 760 || (!F7.device.desktop && !F7.device.ipad)
            },
            async checkIfSheetOpen() {
                await this.timeout(2000);
                const sheet = this?.$f7?.sheet?.get();
                if (sheet?.opened) {
                    this.canOpen = false;
                    this.checkIfSheetOpen();
                    return;
                }

                this.canOpen = true;
                return;
            },
            imageSrc(picture) {
                return picture ? `${process.env.VUE_APP_IMAGES_URL}/${ picture }` : '';
            },
        },
    };
</script>
