import axios from 'axios';
import store from '../store';
import indexedDB from './indexedDB';
import { f7 } from 'framework7-vue';
import { bus } from '../js/utils/bus';
import { axiosConfig } from '../js/utils/axios-config';


const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 60000
})

axiosInstance.interceptors.request.use(async config => {
    return await axiosConfig(config);
}, function (error) {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    if(error.code === 'ECONNABORTED') {
        bus.$emit('timeoutErrorSheetShow', { error: 'timeout', request: error.config.method + ' ' + error.config.url });
    }
    if(error.toJSON() && error.toJSON().message === "Network Error") {
        bus.$emit('timeoutErrorSheetShow', { error: 'timeout', request: error.config.method + ' ' + error.config.url });
    }
    if(error.response) {
        const { status } = error.response;
        if(status === 401) {
            store.dispatch('auth/logout');
            store.dispatch('cart/recalculate');
            f7.views[0].router.navigate('/login', {
                props: {
                    from: await indexedDB.get('mircity-loginFromPath')
                }
            });
        } else {
            bus.$emit('timeoutErrorSheetShow', { error: status, request: error.config.method + ' ' + error.config.url });
            // f7.views[0].router.navigate(`/error/${status}`);
        }
    }
    return Promise.reject(error);
});

export default axiosInstance;
